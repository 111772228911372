.DK0usq_about h2 {
  color: #662d91;
  margin: 0;
  padding-left: 2px;
}

.DK0usq_about p {
  margin: 8px;
}

.DK0usq_about p a {
  color: #f7931e;
}

.DK0usq_about div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.DK0usq_about div a {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 6px;
  margin: 8px 16px;
  padding: .6em 1.6em;
  text-decoration: none;
}

.DK0usq_about div a svg {
  vertical-align: middle;
  display: inline-block;
}

.DK0usq_about div a:hover {
  text-decoration: underline;
}

.DK0usq_tickets {
  color: #fff;
  background-color: #f7931e;
}

.DK0usq_discord {
  color: #fff;
  background-color: #7289da;
}

@media (min-width: 576px) {
  .DK0usq_about div {
    flex-direction: row;
  }
}

.bYe9eG_container {
  width: calc(100% - 16px);
  flex-direction: column;
  margin: 0 8px;
  display: flex;
}

.bYe9eG_image {
  flex: 2;
  align-self: center;
}

.bYe9eG_image img {
  width: 100%;
  box-sizing: border-box;
  object-fit: cover;
  border: 4px solid #662d91;
  border-radius: 12px;
}

.bYe9eG_committee {
  flex: 3;
}

.bYe9eG_committee h2 {
  color: #f7931e;
  margin-bottom: 0;
}

.bYe9eG_committee h3 {
  color: #662d91;
  margin-top: 0;
  padding: 0;
}

.bYe9eG_committee table {
  border-collapse: collapse;
  width: 100%;
  line-height: 1.4;
}

.bYe9eG_committee tr {
  border-bottom: 1px dotted #662d91;
}

.bYe9eG_committee tr:last-child {
  border-bottom: none;
}

.bYe9eG_committee th {
  float: left;
  color: #f9a94b;
  display: contents;
}

.bYe9eG_committee td {
  white-space: nowrap;
}

@media (min-width: 992px) {
  .bYe9eG_container {
    width: 100%;
    flex-direction: row;
    margin: 0;
  }

  .bYe9eG_committee {
    margin-left: 16px;
  }

  .bYe9eG_committee tr {
    border-bottom: none;
  }
}

.yxeYwq_faq {
  background: #f7931e;
  margin: 12px 0;
  padding: 16px;
}

.yxeYwq_faq h2 {
  color: #fff;
  margin: 0;
  padding: 8px;
}

.yxeYwq_faq ul {
  padding: 0;
  list-style-type: none;
}

.yxeYwq_faq li {
  border-left: 3px solid #f9ae56;
  margin: 0 0 16px 8px;
  padding-left: 16px;
}

.yxeYwq_faq summary {
  cursor: pointer;
  color: #fcd4a5;
  font-size: 1.1em;
  font-weight: 600;
}

.yxeYwq_faq p {
  color: #fff;
  margin: 8px 0;
  font-size: 1.1em;
  line-height: 20px;
}

@media (min-width: 576px) {
  .yxeYwq_faq {
    border-radius: 6px;
  }
}

.aIuOea_footer {
  width: 100%;
  justify-content: end;
  padding-bottom: 8px;
  display: flex;
}

.aIuOea_footer * {
  color: #662d91;
}

.aIuOea_footer :hover {
  color: #f7931e;
}

.nTHCOW_header {
  flex-direction: column;
  margin-top: 32px;
  display: flex;
}

.nTHCOW_header img {
  height: 4em;
  margin: 0 auto;
}

.nTHCOW_header span {
  margin: 16px 2vw;
}

.nTHCOW_header span h2 {
  white-space: nowrap;
  color: #f7931e;
  margin: 0;
}

.nTHCOW_header span h3 {
  white-space: nowrap;
  color: #662d91;
  margin: 0;
}

@media (min-width: 576px) {
  .nTHCOW_header {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .nTHCOW_header img {
    margin: 0;
  }

  .nTHCOW_header span {
    margin: 0 2vw;
  }
}

.ejSxcG_icon {
  align-items: inherit;
  fill: currentColor;
}

.ejSxcG_pad {
  margin: 0 .5em;
}

.gNJU5G_photos {
  margin-bottom: 8px;
}

.gNJU5G_photos h2 {
  color: #662d91;
  margin: 0;
  padding-left: 2px;
}

.gNJU5G_photos p {
  margin: 0 8px;
}

.gNJU5G_gallery {
  scrollbar-width: auto;
  scrollbar-color: #f7931e #181226;
  gap: 12px;
  display: flex;
  overflow-x: scroll;
}

.gNJU5G_gallery::-webkit-scrollbar {
  height: 8px;
}

.gNJU5G_gallery::-webkit-scrollbar-track {
  background: #181226;
}

.gNJU5G_gallery::-webkit-scrollbar-thumb {
  background-color: #f7931e;
}

.gNJU5G_image {
  cursor: pointer;
}

.gNJU5G_image img {
  height: 260px;
  width: 400px;
  object-fit: cover;
  border-radius: 6px;
}

.gNJU5G_fullimage {
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(24, 18, 38, .733);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gNJU5G_fullimage img {
  width: auto;
  height: auto;
  max-height: 90vh;
  max-width: 90vw;
}

.jRrIfW_sponsors h2 {
  color: #662d91;
  margin: 0;
  padding-left: 2px;
}

.jRrIfW_sponsors h3 {
  color: #f7931e;
  margin: 4px 16px;
}

.jRrIfW_sponsorlist {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 16px 32px;
  padding: 0;
  display: flex;
}

.jRrIfW_sponsorlist li {
  width: 66%;
  justify-content: center;
  list-style: none;
}

.jRrIfW_sponsorlist .jRrIfW_img {
  background-color: #fff;
  border: 3px solid;
  border-radius: 12px;
  padding: 8px;
  display: flex;
}

.jRrIfW_sponsorlist .jRrIfW_img img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.jRrIfW_sponsorlist a {
  text-decoration: none;
}

.jRrIfW_sponsorlist p {
  text-align: center;
  margin: 8px 0 0;
}

.jRrIfW_sponsorlist [data-type="organisation"] > * {
  color: #00a6d6;
}

.jRrIfW_sponsorlist [data-type="primary"] > * {
  color: #f7931e;
}

.jRrIfW_sponsorlist [data-type="secondary"] > * {
  color: #662d91;
}

@media (min-width: 576px) {
  .jRrIfW_sponsorlist li {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .jRrIfW_sponsorlist li {
    width: 33%;
  }
}

@media (min-width: 1200px) {
  .jRrIfW_sponsorlist li {
    width: 20%;
  }
}

.pHzBaG_timeline h2 {
  color: #f7931e;
  margin: 0;
}

.pHzBaG_timeline p {
  margin: 0;
}

.pHzBaG_container {
  flex-direction: column;
  display: flex;
}

.pHzBaG_container h3 {
  color: #662d91;
  margin: 4px 16px;
}

.pHzBaG_container div {
  flex-direction: column;
  display: flex;
}

.pHzBaG_timelineEntry {
  margin: 0 8px;
}

.pHzBaG_timelineEntry time {
  font-style: italic;
}

.pHzBaG_timelineEntry time svg {
  padding-right: 4px;
}

.pHzBaG_timelineEntry div {
  border: 2px solid #662d91;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px 16px;
}

.pHzBaG_timelineEntry h4 {
  color: #f7931e;
  margin: 0;
}

.pHzBaG_timelineEntry h4 svg {
  padding-right: 4px;
}

@media (min-width: 768px) {
  .pHzBaG_container {
    flex-direction: row;
  }

  .pHzBaG_container div {
    width: 92%;
  }

  .pHzBaG_timelineEntry {
    align-self: center;
  }
}

html, body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background: #181226;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 1.5;
  display: flex;
}

@media (min-width: 576px) {
  body {
    width: 80%;
    margin: 0 10%;
  }
}

@media (min-width: 768px) {
  body {
    width: 70%;
    margin: 0 15%;
  }
}

@media (min-width: 992px) {
  body {
    width: 60%;
    margin: 0 20%;
  }
}

/*# sourceMappingURL=index.4b8c36bc.css.map */
